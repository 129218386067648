import React from 'react'
import { graphql } from 'gatsby'
import styles from './rankings.module.sass'

import Layout from '../components/layout'
import SEO from '../components/seo'
import Card from '../components/card'

import { Tab, Tabs, TabList, TabPanel } from 'react-tabs'

const RankingsPage = ({ data }) => (
   <Layout>
      <SEO title="Rankings &amp; Recognition" />
      <div className={['page', styles.page__awards].join(' ')}>
         <div className={['page--header', styles.page__header].join(' ')}>
            <h1 className={['page--title', styles.page__title].join(' ')}>
               Rankings &amp; Recognition
            </h1>
            <p
               className={['page--subheader', styles.page__subheader].join(
                  ' '
               )}>
               Please join us in honoring our President &amp; CEO,{' '}
               <b>Edward G. Sella</b>,{' '}
               <small>
                  CPA/PFS, CFP<sup>&reg;</sup>
               </small>{' '}
               for his perseverance and continued dedication in helping to meet
               the individual financial challenges of our diverse investment
               family.
            </p>
         </div>
         <Tabs
            className="l-tabs"
            selectedTabClassName="l-tabs__tab--selected"
            selectedTabPanelClassName="l-tabs__panel--selected">
            <div className={['tab--navigation', styles.awards__tabs].join(' ')}>
               <TabList className="l-tabs__tab-list">
                  <Tab className="l-tabs__tab">All</Tab>
                  <Tab className="l-tabs__tab">Barron's</Tab>
                  <Tab className="l-tabs__tab">FA Magazine</Tab>
                  <Tab className="l-tabs__tab">Financial Times</Tab>
               </TabList>
            </div>

            <TabPanel className="l-tabs__panel">
               <div className={styles.awards}>
                  {data.allContentfulAward &&
                     data.allContentfulAward.edges.map(({ node }, index) => (
                        <Card
                           key={index}
                           title={node.title}
                           meta={
                              'Ranked by ' +
                              (node.source ? node.source : 'Unknown Source') +
                              ' • ' +
                              (node.yearAwarded && !node.dates
                                 ? node.yearAwarded
                                 : node.dates)
                           }
                           content={node.preview}
                           actionAccordion="true"
                           contentAccordion={node.criteria.criteria}
                           actionText="View Criteria"
                        />
                     ))}
               </div>
            </TabPanel>
            <TabPanel className="l-tabs__panel">
               <div className={styles.awards}>
                  {data.allContentfulAward &&
                     data.allContentfulAward.edges.map(
                        ({ node }, index) =>
                           node.source === 'Barron’s' && (
                              <Card
                                 key={index}
                                 type="award"
                                 title={node.title}
                                 meta={
                                    'Ranked by ' +
                                    (node.source
                                       ? node.source
                                       : 'Unknown Source') +
                                    ' • ' +
                                    (node.yearAwarded && !node.dates
                                       ? node.yearAwarded
                                       : node.dates)
                                 }
                                 content={node.preview}
                                 actionAccordion="true"
                                 contentAccordion={node.criteria.criteria}
                                 actionText="View Criteria"
                              />
                           )
                     )}
               </div>
            </TabPanel>
            <TabPanel className="l-tabs__panel">
               <div className={styles.awards}>
                  {data.allContentfulAward &&
                     data.allContentfulAward.edges.map(
                        ({ node }, index) =>
                           node.source === 'FA Magazine' && (
                              <Card
                                 key={index}
                                 title={node.title}
                                 meta={
                                    'Ranked by ' +
                                    (node.source
                                       ? node.source
                                       : 'Unknown Source') +
                                    ' • ' +
                                    (node.yearAwarded && !node.dates
                                       ? node.yearAwarded
                                       : node.dates)
                                 }
                                 content={node.preview}
                                 actionAccordion="true"
                                 contentAccordion={node.criteria.criteria}
                                 actionText="View Criteria"
                              />
                           )
                     )}
               </div>
            </TabPanel>
            <TabPanel className="l-tabs__panel">
               <div className={styles.awards}>
                  {data.allContentfulAward &&
                     data.allContentfulAward.edges.map(
                        ({ node }, index) =>
                           node.source === 'Financial Times' && (
                              <Card
                                 key={index}
                                 title={node.title}
                                 meta={
                                    'Ranked by ' +
                                    (node.source
                                       ? node.source
                                       : 'Unknown Source') +
                                    ' • ' +
                                    (node.yearAwarded && !node.dates
                                       ? node.yearAwarded
                                       : node.dates)
                                 }
                                 content={node.preview}
                                 actionAccordion="true"
                                 contentAccordion={node.criteria.criteria}
                                 actionText="View Criteria"
                              />
                           )
                     )}
               </div>
            </TabPanel>
         </Tabs>
      </div>
   </Layout>
)

export default RankingsPage

export const query = graphql`
   query {
      allContentfulAward(
         filter: { node_locale: { eq: "en-US" } }
         sort: { fields: [yearAwarded, dates], order: DESC }
      ) {
         edges {
            node {
               title
               preview
               source
               dates
               yearAwarded
               criteria {
                  criteria
               }
            }
         }
      }
   }
`
